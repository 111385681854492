import React from 'react';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import { Box, DateInput, Heading, Image, Select, Text } from 'grommet';
import tw from 'twin.macro';
import { Input } from 'tutadoo-design-system/src/components/Input';
import imagePlaceHolder from '../../../assets/image_placeholder.png';
import { Button } from 'tutadoo-design-system/src/components/Button';
import TextArea from 'components/TextArea';
import { EditIcon, CalendarIcon, ClockIcon } from 'components/icons';
import BoxSelect from 'components/BoxSelect';

const Container = tw.div`max-w-[864px] mx-auto mt-7`;
const Divider = tw.div`w-full mx-auto h-0 my-6`;

const btnStyles = {
  background: 'transparent',
  border: '1px solid #8264EE',
  borderRadius: '8px'
};

function createEvent() {
  const [value, setValue] = React.useState();
  const onChange = (event) => {
    const nextValue = event.value;
    console.log('onChange iso date:', nextValue);
    console.log('onChange utc date:', new Date(nextValue));
    setValue(nextValue);
  };

  return (
    <UserDashboard>
      <Container>
        <Heading level={'3'} size="medium">
          Create an event
        </Heading>

        <Heading margin={{ top: '24px' }} level={'4'} size="small">
          1. Basic Info
        </Heading>
        <Text as="p">Start with the key details about your new event.</Text>

        <Divider style={{ border: '1px solid #EBECED', marginTop: '24px', marginBottom: '24px' }} />
        <Box>
          <Input label="Event title" />
        </Box>

        <div className="flex w-full mt-6">
          <Box width={'50%'} className="mr-4">
            <Text size="xsmall" weight={'bold'} as="label">
              About
            </Text>
            <Select
              options={['Webinar', 'Webinar2', 'Webinar3']}
              value={'Webinar'}
              defaultValue="Webinar"
              //   onChange={({ option }) => setValue(option)}
            />
          </Box>
          <Box width={'50%'}>
            <Text size="xsmall" weight={'bold'} as="label">
              About
            </Text>
            <Select
              options={['Seasonal & holiday', 'Seasonal & holiday1', 'Seasonal & holiday2']}
              value={'Seasonal & holiday'}
              defaultValue="Seasonal & holiday"
            />
          </Box>
        </div>

        <Box margin={{ top: '24px' }}>
          <Text size="medium" weight={'bold'} as="p">
            Event Cover
          </Text>
          <Text as="p">JPG, GIF or PNG. Required size 1200px x 600px and 2MB or less..</Text>
        </Box>
        <Box>
          <Image
            src={imagePlaceHolder}
            style={{
              marginTop: '24px',
              minHeight: '180px',
              width: '100%',
              maxWidth: '360px',
              borderRadius: '8px'
            }}
          />
          <Box margin={{ top: '24px', bottom: '10px' }} direction="row">
            <Button size="medium" primary label="Upload new image" margin={{ right: '10px' }} />
            <Button size="medium" secondary label="Delete" />
          </Box>
          <Divider
            style={{ border: '1px solid #EBECED', marginTop: '24px', marginBottom: '24px' }}
          />
        </Box>
        <Box margin={{ top: '24px' }}>
          <Text size="medium" weight={'bold'} as="p">
            Event Description
          </Text>
          <Text as="p">Describe your event</Text>
        </Box>
        <Box style={{ border: '1px solid #EBECED' }} margin={{ top: '24px', bottom: '30px' }}>
          <TextArea />
        </Box>
        <Heading margin={{ top: '24px' }} level={'4'} size="small">
          2. Location
        </Heading>
        <Text as="p">
          Help people in the area discover your event and let attendees know where to show up.
        </Text>
        <Divider style={{ border: '1px solid #EBECED', marginTop: '24px', marginBottom: '24px' }} />
        <BoxSelect value="Single" options={['single', 'Multiply']} />
        <Box>
          <Input label="Venue location" reverse icon={<EditIcon />} />
        </Box>
        <Text size="medium" weight={'bold'} as="p">
          Street Address
        </Text>
        <div className="flex w-full mt-6">
          <Box width={'50%'} className="mr-4">
            <Input label="Address 1" />
          </Box>
          <Box width={'50%'}>
            <Input label="Address 2" />
          </Box>
        </div>
        <div className="flex w-full mt-6">
          <Box width={'50%'} className="mr-4">
            <Input label="City" />
          </Box>
          <Box direction="row" width={'50%'}>
            <Box width={'50%'} className="mr-4">
              <Input label="State/Province" />
            </Box>
            <Box width={'50%'}>
              <Input label="Postal Code" />
            </Box>
          </Box>
        </div>
        <Box width={{ max: '416px' }}>
          <Text size="xsmall" weight={'bold'} as="label">
            Country
          </Text>
          <Select
            options={['Kenya', 'Nigeria', 'Canada']}
            value={'Kenya'}
            defaultValue="Webinar"
            //   onChange={({ option }) => setValue(option)}
          />
        </Box>
        <Heading margin={{ top: '24px' }} level={'4'} size="small">
          3. Date & Time
        </Heading>
        <Text as="p">
          Tell event-goers when your event starts and ends so they can make plans to attend.
        </Text>
        <Divider style={{ border: '1px solid #EBECED', marginTop: '24px', marginBottom: '24px' }} />
        <Text>Single event happens once and can last multiple days</Text>
        <Box margin={{ top: '32px', bottom: '24px' }} direction="row" gap="10px">
          <Button size="medium" secondary style={btnStyles} label="Single" />
          <Button size="medium" secondary style={btnStyles} label="Multiply" />
          <Button size="medium" secondary style={btnStyles} label="Multiply" />
        </Box>
        <div className="flex w-full mt-6 items-center">
          <Box gap="medium" width="50%" justify="start" className="mr-4">
            <Box margin={{ bottom: '12px' }} width="100%">
              <Text margin={{ top: '6px', bottom: '6px' }} size="xsmall" weight={'bold'} as="label">
                Event starts
              </Text>
              <DateInput
                icon={<CalendarIcon />}
                format="dd/mm/yyyy"
                value={value}
                onChange={onChange}
              />
            </Box>
          </Box>

          <Box margin={{ left: '' }} width={'50%'}>
            <Input label="Start time" icon={<ClockIcon />} />
          </Box>
        </div>
        <div className="flex w-full mt-6 items-center">
          <Box gap="medium" width="50%" justify="start" className="mr-4">
            <Box margin={{ bottom: '12px' }} width="100%">
              <Text margin={{ top: '6px', bottom: '6px' }} size="xsmall" weight={'bold'} as="label">
                Event Ends
              </Text>
              <DateInput
                icon={<CalendarIcon />}
                format="dd/mm/yyyy"
                value={value}
                onChange={onChange}
              />
            </Box>
          </Box>

          <Box margin={{ left: '' }} width={'50%'}>
            <Input label="End time" icon={<ClockIcon />} />
          </Box>
        </div>
        <Box width={'50%'}>
          <Text size="xsmall" weight={'bold'} as="label">
            Time zone
          </Text>
          <Select
            options={['Seasonal & holiday', 'Seasonal & holiday1', 'Seasonal & holiday2']}
            value={'Seasonal & holiday'}
            defaultValue="Seasonal & holiday"
          />
        </Box>
      </Container>
      <Box
        height={{ min: '80px' }}
        style={{ boxShadow: '0px -1px 0px #EBECED' }}
        direction="row"
        margin={{ top: '56px' }}
        justify="end"
        align="center"
        width={'100%'}
        pad={'5px 20px'}
      >
        <Button size="medium" primary label="Create event" />
      </Box>
    </UserDashboard>
  );
}

createEvent.propTypes = {};

export default createEvent;
