import { Box } from 'grommet';
import { Button } from 'tutadoo-design-system/src/components/Button';
import React from 'react';

const BoxSelect = ({
  options,
  value,
  onChange = () => {},
  width,
  margin,
  maxWidth
}: {
  options: string[];
  value: string;
  onChange?: (val: string) => void;
  width?: string;
  maxWidth?: string;
  margin?: string;
}) => (
  <Box margin={{ top: '32px', bottom: '24px' }} direction="row" gap="10px">
    {options.map((opt, i) => (
      <Button
        key={`${opt}_${i}`}
        onClick={() => onChange(opt)}
        secondary
        style={{
          background: 'transparent',
          border: value === opt ? '1px solid #8264EE' : '1px solid #DCDCDE',
          borderRadius: '8px',
          width,
          maxWidth,
          margin
        }}
        label={opt}
      />
    ))}
  </Box>
);

BoxSelect.propTypes = {};

export default BoxSelect;
