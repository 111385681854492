
import React, { SVGProps } from 'react';

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M0.998047 15.4613V18.5013C0.998047 18.7813 1.21805 19.0013 1.49805 19.0013H4.53805C4.66805 19.0013 4.79805 18.9513 4.88805 18.8513L15.808 7.94128L12.058 4.19128L1.14805 15.1013C1.04805 15.2013 0.998047 15.3213 0.998047 15.4613ZM18.708 5.04128C19.098 4.65128 19.098 4.02128 18.708 3.63128L16.368 1.29128C15.978 0.901279 15.348 0.901279 14.958 1.29128L13.128 3.12128L16.878 6.87128L18.708 5.04128Z" fill="#62656D"/>
</svg>
);


export default EditIcon;